


































































import { Component, Vue } from 'vue-property-decorator';
import LoginStore from '@/lib/LoginStore';

@Component({
  components: {
  },
})

export default class App extends Vue {
  Logout(): void {
    LoginStore.Logout();
    this.$router.push('/Login');
  }
}
