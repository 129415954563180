














































































import LoginStore from '@/lib/LoginStore';
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component({
    components: {},
})
export default class Home extends Vue {
    @Prop() private Redirect!: string;

    public message = '';

    public form = {
        loginId: '',
        password: '',
    };

    OnSubmitLoginForm(): void {
        if (this.Redirect === undefined) {
            this.Redirect = '/';
        }

        this.message = LoginStore.Login(
            this.form.loginId,
            this.form.password,
            this.Redirect,
            this.$router
        );
    }
}
