import VueRouter from 'vue-router';
import firebase from 'firebase/app'
import 'firebase/auth'

/**
 * storeクラスです。
 */
export default class LoginStore {
    public static LoginState = false;

    /**
     * ログイン処理の初期化を行います。
     */
    public static Init(): void {
        const currentUser = firebase.auth().currentUser;
        if (currentUser == null) {
            this.LoginState = false;
        } else {
            this.LoginState = true;
        }
    }

    /**
     * ログイン処理を行います。
     * @param loginId ログインIDを指定します。
     * @param password パスワードを指定します。
     * @param redirect リダイレクト先を指定します。
     * @param router vueRouterオブジェクトを指定します。
     * @retruns エラーメッセージを返します。
     */
    public static Login(loginId: string, password: string, redirect: string, router: VueRouter): string {
        let ret = '';

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(async () => {
                // Existing and future Auth states are now persisted in the current
                // session only. Closing the window would clear any existing state even
                // if a user forgets to sign out.
                // ...
                // New sign-in will be persisted with session persistence.
                firebase.auth().signInWithEmailAndPassword(loginId, password).then((data) => {
                    if (data != null) {
                        this.LoginState = true;

                        firebase.auth().onAuthStateChanged((user) => {
                            if (user) {
                                this.LoginState = true;
                            } else {
                                this.Logout();
                            }
                        });

                        router.push(redirect);
                    }
                }).catch((error) => {
                    ret = error.message;
                });
            })
            .catch((error) => {
                // Handle Errors here.
                // var errorCode = error.code;
                ret = error.message;
            });

        return ret;
    }

    /**
     * ログアウト処理を行います。
     */
    public static Logout(): void {
        firebase.auth().signOut().then(() => {
            this.LoginState = false;
        }).catch((error) => {
            console.error(error);
        });
    }

    /**
     * Cookieの取得を行います。
     * @returns Cookieの連想配列を返します。
     */
    private static GetCookie() {
        const cookie = document.cookie;
        const cookieArray = cookie.split(";");
        const ret: {
            [key: string]: string;
        } = {};

        cookieArray.forEach(x => {
            const KeyValue = x.split('=');
            const key = KeyValue[0].replace(' ', '');
            const value = KeyValue[1];

            ret[key] = value;
        });

        return ret;
    }
}