import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import firebase from 'firebase/app'

import BootstrapVue from 'bootstrap-vue'
import '@/assets/bootstrap.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faAngleLeft, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//BootStorap vue初期化
Vue.use(BootstrapVue);

//fontawasome初期化
library.add(faTrash, faAngleLeft, faAngleUp)
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBS_Ydk7NES_-cfo1oMkTofCmcbcHs34Rg",
  authDomain: "souatareru.firebaseapp.com",
  databaseURL: "https://souatareru-default-rtdb.firebaseio.com",
  projectId: "souatareru",
  storageBucket: "souatareru.appspot.com",
  messagingSenderId: "159111953812",
  appId: "1:159111953812:web:16309254cbc6057be7e381",
  measurementId: "G-GLHTBC8QCJ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');