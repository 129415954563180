import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';

import LoginStore from '../lib/LoginStore';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login/:Redirect?',
        name: 'Login',
        meta: { isPublic: true },
        props: true,
        component: Login
    },
    {
        path: '/v1',
        name: 'V1',
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "v1" */ '../views/V1.vue'),
    },
    {
        path: '/v2',
        name: 'V2',
        component: () => import(/* webpackChunkName: "v2" */ '../views/V2.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    },
    {
        path: '/reminder',
        name: 'Reminder',
        meta: { isPublic: true },
        component: () => import(/* webpackChunkName: "Reminder" */ '../views/Reminder.vue'),
    },
    {
        path: '/data',
        name: 'Data',
        component: () => import(/* webpackChunkName: "data" */ '../views/Data.vue'),
    },
    {
        path: '/view_v1/:tournamentId',
        name: 'View_V1',
        props: true,
        component: () => import(/* webpackChunkName: "data" */ '../views/View_V1.vue'),
    },
    {
        path: '/view_v2/:tournamentId',
        name: 'View_V2',
        props: true,
        component: () => import(/* webpackChunkName: "data" */ '../views/View_V2.vue'),
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    LoginStore.Init();

    // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
    if (to.matched.some(record => !record.meta.isPublic) && !LoginStore.LoginState) {
        next({ name: 'Login', params: { Redirect: to.fullPath } });
    } else {
        next();
    }
});

export default router
